// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  resetPassword: '/reset-password',
  register: '/register',
  cloverLogin: '/clover/login',
  shopifyLogin: '/shopify/login',
};

export const USER_INVITE = {
  invite: '/invite',
};

const ROOTS_OAUTH = '/oauth';
export const PATH_OAUTH = {
  root: ROOTS_OAUTH,
  consent: path(ROOTS_OAUTH, '/consent'),
};

const ROOTS_MAIN = '';
export const PATH_MAIN = {
  root: ROOTS_MAIN,
  getting_started: path(ROOTS_MAIN, '/getting-started'),
  dashboard: path(ROOTS_MAIN, '/dashboard'),
  dashboardv2: path(ROOTS_MAIN, '/dashboardv2'),
  members: path(ROOTS_MAIN, '/members'),
  member_profile: path(ROOTS_MAIN, '/members/:slug/profile'),
  campaigns: path(ROOTS_MAIN, '/campaigns'),
  campaign_edit: path(ROOTS_MAIN, '/campaigns/:slug/edit'),
  notifications: path(ROOTS_MAIN, '/notifications'),
  notifications_edit: path(ROOTS_MAIN, '/notifications/:channel/:slug/edit'),
};

const ROOTS_REWARDS_PROGRAM = path(ROOTS_MAIN, '/rewards-program');

const ROOTS_REWARDS_PROGRAM_POINTS = path(ROOTS_REWARDS_PROGRAM, '/points');
const ROOTS_REWARDS_PROGRAM_POINTS_EARNING = path(ROOTS_REWARDS_PROGRAM_POINTS, '/point-rules');
const ROOTS_REWARDS_PROGRAM_POINTS_REDEEMING = path(ROOTS_REWARDS_PROGRAM_POINTS, '/reward-levels');
const ROOTS_REWARDS_PROGRAM_PUNCH_CARD = path(ROOTS_REWARDS_PROGRAM, '/punch-card-program');
const ROOTS_REWARDS_PROGRAM_CASHBACK_PROGRAM = path(ROOTS_REWARDS_PROGRAM, '/cashback-program');

const ROOTS_REWARDS_PROGRAM_retention_autopilots = path(
  ROOTS_REWARDS_PROGRAM,
  '/retention-autopilot'
);

const ROOTS_REWARDS_PROGRAM_VOUCHERS = path(ROOTS_REWARDS_PROGRAM, '/vouchers');

const ROOTS_REWARDS_PROGRAM_VIP = path(ROOTS_REWARDS_PROGRAM, '/vip-program');
const ROOTS_REWARDS_PROGRAM_VIP_TIERS = path(ROOTS_REWARDS_PROGRAM_VIP, '/tiers/:slug');

const ROOTS_REWARDS_PROGRAM_REFERRALS = path(ROOTS_REWARDS_PROGRAM, '/referral-program');
export const PATH_REWARDS_PROGRAM = {
  root: ROOTS_REWARDS_PROGRAM,
  points: {
    root: ROOTS_REWARDS_PROGRAM_POINTS,
    earning: ROOTS_REWARDS_PROGRAM_POINTS_EARNING,
    redeeming: ROOTS_REWARDS_PROGRAM_POINTS_REDEEMING,
  },
  cashback_program:{
   root: ROOTS_REWARDS_PROGRAM_CASHBACK_PROGRAM,
  },
  punch_cards: {
    root: ROOTS_REWARDS_PROGRAM_PUNCH_CARD,
  },
  retention_autopilots: {
    root: ROOTS_REWARDS_PROGRAM_retention_autopilots,
  },
  vouchers: {
    root: ROOTS_REWARDS_PROGRAM_VOUCHERS,
  },
  vip: {
    root: ROOTS_REWARDS_PROGRAM_VIP,
    tiers: ROOTS_REWARDS_PROGRAM_VIP_TIERS,
    entry_reward: path(ROOTS_REWARDS_PROGRAM_VIP_TIERS, '/entry-reward'),
  },
  referrals: {
    root: ROOTS_REWARDS_PROGRAM_REFERRALS,
  },
  activity: path(ROOTS_REWARDS_PROGRAM, '/activity'),
  reports: path(ROOTS_REWARDS_PROGRAM, '/reports'),
  reportsv2: path(ROOTS_REWARDS_PROGRAM, '/reportsv2'),
  custom: path(ROOTS_REWARDS_PROGRAM, '/custom'),
};

const ROOTS_GIFT_CARD_PROGRAM = path(ROOTS_MAIN, '/gift-card-program');
export const PATH_GIFT_CARD_PROGRAM = {
  root: ROOTS_GIFT_CARD_PROGRAM,
  gift_cards: path(ROOTS_GIFT_CARD_PROGRAM, '/gift-cards'),
  gift_card_transactions: path(ROOTS_GIFT_CARD_PROGRAM, '/gift-card-transactions'),
  gift_items: path(ROOTS_GIFT_CARD_PROGRAM, '/gift-items'),
  site: path(ROOTS_GIFT_CARD_PROGRAM, '/site'),
  setup: path(ROOTS_GIFT_CARD_PROGRAM, '/gift-card-setup'),
  gift_card_reconciliation_report: path(
    ROOTS_GIFT_CARD_PROGRAM,
    '/gift-card-reconciliation-report'
  ),
};

const ROOTS_STORE_CREDIT_PROGRAM = path(ROOTS_MAIN, '/store-credit-program');
export const PATH_STORE_CREDIT_PROGRAM = {
  root: ROOTS_STORE_CREDIT_PROGRAM,
  store_credit_cards: path(ROOTS_STORE_CREDIT_PROGRAM, '/store-credit'),
  store_credit_card_transactions: path(
    ROOTS_STORE_CREDIT_PROGRAM,
    '/store-credit-card-transactions'
  ),
  site: path(ROOTS_STORE_CREDIT_PROGRAM, '/site'),
  setup: path(ROOTS_STORE_CREDIT_PROGRAM, '/store-credit-setup'),
};

const ROOTS_SETTINGS = path(ROOTS_MAIN, '/settings');
export const PATH_SETTINGS = {
  root: ROOTS_SETTINGS,
  wallet_pass: path(ROOTS_SETTINGS, '/wallet-pass'),
  member_site: path(ROOTS_SETTINGS, '/member-site'),
  account: path(ROOTS_SETTINGS, '/account'),
  billing: path(ROOTS_SETTINGS, '/billing'),
  manage_user: path(ROOTS_SETTINGS, '/manage-users'),
  locations: path(ROOTS_SETTINGS, '/locations'),
};

export const PATH_WEBHOOK_REDIRECT = {
  cloverCallback: '/clover/callback',
  shopifyCallback: '/shopify/callback',
};
