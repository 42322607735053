import { lazy, Suspense } from 'react'; // components
import LoadingScreen from '../components/loading-screen'; // ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));

export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));

export const DashboardPage = Loadable(lazy(() => import('../pages/main/DashboardPage')));

export const DashboardPagev2 = Loadable(lazy(() => import('../pages/main/DashboardPagev2')));

export const RegisterPage = Loadable(lazy(() => import('../pages/register/RegisterPage')));

export const CloverLoginPage = Loadable(lazy(() => import('../pages/auth/CloverLoginPage')));

export const ShopifyLoginPage = Loadable(lazy(() => import('../pages/auth/ShopifyLoginPage')));

// ----------------------------------------------------------------------

export const UserInvitePage = Loadable(lazy(() => import('../pages/invite/InvitePage')));

// ----------------------------------------------------------------------

export const GettingStartedPage = Loadable(lazy(() => import('../pages/main/GettingStartedPage')));

// ----------------------------------------------------------------------

export const OAuthConsentPage = Loadable(lazy(() => import('../pages/oauth/OAuthConsentPage')));

// ----------------------------------------------------------------------

export const MembersPage = Loadable(lazy(() => import('../pages/members/MembersPage')));

// ----------------------------------------------------------------------

export const CampaignsPage = Loadable(lazy(() => import('../pages/campaigns/CampaignsPage')));
export const CampaignEditPage = Loadable(lazy(() => import('../pages/campaigns/CampaignEditPage')));

// ----------------------------------------------------------------------

export const NotificationsPage = Loadable(
  lazy(() => import('../pages/notifications/NotificationsPage'))
);
export const NotificationEditPage = Loadable(
  lazy(() => import('../pages/notifications/NotificationEditPage'))
);

// ----------------------------------------------------------------------

export const PointsEarningPage = Loadable(
  lazy(() => import('../pages/rewards-program/points/PointsEarningPage'))
);

// ----------------------------------------------------------------------
export const PointsRedeemingPage = Loadable(
  lazy(() => import('../pages/rewards-program/points/PointsRedeemingPage'))
);

// ----------------------------------------------------------------------
export const CashbackProgramPage = Loadable(
  lazy(() => import('../pages/rewards-program/cashback-program/CashbackProgramPage'))
);

// ----------------------------------------------------------------------

export const PunchCardPage = Loadable(
  lazy(() => import('../pages/rewards-program/punch-card/PunchCardPage'))
);

// ----------------------------------------------------------------------

export const AutomatedRewardsPage = Loadable(
  lazy(() => import('../pages/rewards-program/retention-autopilots/RetentionAutopilotsPage'))
);

// ----------------------------------------------------------------------

export const VouchersPage = Loadable(
  lazy(() => import('../pages/rewards-program/vouchers/VouchersPage'))
);

// ----------------------------------------------------------------------

export const VipPage = Loadable(lazy(() => import('../pages/rewards-program/vip/VipPage')));
export const VipTierPage = Loadable(lazy(() => import('../pages/rewards-program/vip/VipTierPage')));

// ----------------------------------------------------------------------

export const ReferralsPage = Loadable(
  lazy(() => import('../pages/rewards-program/referrals/ReferralPage'))
);

// ----------------------------------------------------------------------

export const RewardsProgramActivityPage = Loadable(
  lazy(() => import('../pages/rewards-program/RewardsProgramActivityPage'))
);

// ----------------------------------------------------------------------

export const RewardsProgramReportPage = Loadable(
  lazy(() => import('../pages/rewards-program/RewardsProgramReportPage'))
);

// ----------------------------------------------------------------------

export const RewardsProgramReportPageV2 = Loadable(
  lazy(() => import('../pages/rewards-program/RewardsProgramReportPageV2'))
);

// ----------------------------------------------------------------------

export const GiftCardsPage = Loadable(
  lazy(() => import('../pages/gift-card-program/GiftCardsPage'))
);

export const GiftCardTransactionsPage = Loadable(
  lazy(() => import('../pages/gift-card-program/GiftCardTransactionsPage'))
);

export const GiftItemsPage = Loadable(
  lazy(() => import('../pages/gift-card-program/GiftItemsPage'))
);

export const GiftProgramSetupPage = Loadable(
  lazy(() => import('../pages/gift-card-program/GiftProgramSetup'))
);

export const GiftProgramReconcilationReportPage = Loadable(
  lazy(() => import('../pages/gift-card-program/GiftCardReconciliationReportPage'))
);

// ----------------------------------------------------------------------

export const StoreCreditCardsPage = Loadable(
  lazy(() => import('../pages/store-credit-program/StoreCreditCardsPage'))
);

export const StoreCreditCardTransactionsPage = Loadable(
  lazy(() => import('../pages/store-credit-program/StoreCreditCardTransactionsPage'))
);

export const StoreCreditProgramSetupPage = Loadable(
  lazy(() => import('../pages/store-credit-program/StoreCreditProgramSetup'))
);

// ----------------------------------------------------------------------

export const WalletPassPage = Loadable(lazy(() => import('../pages/settings/WalletPassPage')));
export const MemberSitePage = Loadable(lazy(() => import('../pages/settings/MemberSitePage')));
export const AccountPage = Loadable(lazy(() => import('../pages/settings/AccountPage')));
export const BillingPage = Loadable(lazy(() => import('../pages/settings/BillingPage')));
export const ManageUserPage = Loadable(lazy(() => import('../pages/settings/ManageUserPage')));
export const LocationsPage = Loadable(lazy(() => import('../pages/settings/LocationsPage')));
