import { addMethod, string, mixed } from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';
import { restrictedWords } from '../assets/data';

// ----------------------------------------------------------------------

addMethod(string, 'phone', function phone(countryCode = 'US', message) {
  return this.test('phone', message, function test(value) {
    const { path, createError } = this;
    const isValid = isValidPhoneNumber(value, countryCode);
    if (!isValid) {
      return createError({
        path,
        message: message ?? `Must be a valid phone number`,
      });
    }
    return true;
  });
});

addMethod(string, 'validateRestrictedWord', function validateRestrictedWord(smsStatus = false) {
  return this.test('forbidden-word-test', function test(value) {
    if (!smsStatus) return true;
    const { path, createError } = this;

    let words = [];

    const filterWords = (regex) =>
      value
        .split(regex)
        .filter((word) =>
          restrictedWords.some(
            (forbiddenWord) => forbiddenWord.toLowerCase() === word.toLowerCase()
          )
        ) ?? [];

    words.push(
      ...filterWords(/\s+|[,.;!?>|</";'+*~`@?^&()%$#!]+/),
      ...filterWords(/\s+|[,;?'"]+\n?/)
    );

    if (words.length > 0) {
      words = new Set(words);
      const filteredWords = Array.from(words)
        .map((word) => `"${word}"`)
        .join(' ');

      return createError({
        path,
        message: `Restricted words found: ${filteredWords}`,
      });
    }

    return true;
  });
});

addMethod(string, 'hexcode', function hexcode(message) {
  return this.test('hexcode', message, function test(value) {
    const { path, createError } = this;
    const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
    const isValid = hexRegex.test(value);
    if (!isValid) {
      return createError({
        path,
        message: message ?? `Hexcode is required`,
      });
    }
    return true;
  });
});

addMethod(mixed, 'imageSize', function imageSize(width, height, message) {
  return this.test(
    'imageSize',
    message || `Image must be ${width}x${height} pixels`,
    function imageValidate(value) {
      if (!value) return true;
      const { createObjectURL, revokeObjectURL } = window.URL || window;
      const image = new Image();
      return new Promise((resolve) => {
        image.onload = () => {
          resolve(image.width === width && image.height === height);
        };
        image.onerror = () => {
          resolve(false);
        };
        image.src = createObjectURL(value);
        image.onloadend = () => revokeObjectURL(image.src);
      }).then((isValid) => {
        if (!isValid) {
          return this.createError({
            message: message || `Image must be ${width}x${height} pixels`,
          });
        }
        return true;
      });
    }
  );
});

Yup.addMethod(Yup.string, 'uriScheme', function uriScheme() {
  return this.test('uriScheme', '', function test(value) {
    const { path, createError } = this;

    const defaultErrorMessage = 'Invalid URL format. Accepted formats: mailto:, tel:, http(s):';
    let errorMsg = '';
    let isValid = true;

    try {
      if (value.startsWith('mailto:')) {
        const email = value.split('mailto:')?.[1];

        Yup.string().email('Valid Email is required').required('Email is required').validateSync(email);
      } else if (value.startsWith('tel:')) {
        const telNumber = value.split('tel:')?.[1];

        const isValidPhone = isValidPhoneNumber(telNumber, 'US');

        if (!isValidPhone) {
          errorMsg = 'Please provide a valid phone number';
          isValid = false;
        }
      } else {
        Yup.string().url(defaultErrorMessage).validateSync(value);
      }
    } catch (err) {
      const msg = err?.toString()?.split('ValidationError:')?.[1] ?? defaultErrorMessage;
      errorMsg = msg;
      isValid = false;
    }

    if (!isValid) {
      return createError({
        path,
        message: errorMsg ?? defaultErrorMessage,
      });
    }

    return true;
  });
});
